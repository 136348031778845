import { all, fork } from 'redux-saga/effects';
import { watchLocation } from './location';
import { watchItem } from './item';
import { watchOrder } from './order';
import { watchAuth } from './auth';
import { watchGetLocale } from './locale';
import { watchReceipt } from './receipt';

export default function* rootSaga() {
  yield all([
    fork(watchLocation),
    fork(watchItem),
    fork(watchOrder),
    fork(watchAuth),
    fork(watchGetLocale),
    fork(watchReceipt),
  ]);
};
