import React from 'react';
import { connect } from 'react-redux';
import ErrorMessage from '../errorMessage';

class BasicLayout extends React.Component {
  render() {
    const { children, style, errors } = this.props;
    return (
      <div style={style}>
        {errors.isError && <ErrorMessage message={errors.message} />}
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { errors: state.errors };
};

export default connect(mapStateToProps)(BasicLayout);
