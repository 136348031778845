import React from 'react';
import { FormattedNumber } from 'react-intl';

const FormattedPrice = ({ value, currency }) => {
  return (
    <FormattedNumber
      style="currency"
      value={value}
      currency={currency}
      minimumFractionDigits="0"
      maximumFractionDigits="0"
    />
  );
};

export default FormattedPrice;
