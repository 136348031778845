import { handleActions, createAction, combineActions } from 'redux-actions';
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
} from './auth';
import {
  ADD_ITEM,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAILURE,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
} from './order';

const PREFIX = 'REDUCERS/UI';

export const OPEN_CART_MODAL = `${PREFIX}/OPEN_CART_MODAL`;
export const CLOSE_CART_MODAL = `${PREFIX}/CLOSE_CART_MODAL`;

export const openCartModal = createAction(OPEN_CART_MODAL);
export const closeCartModal = createAction(CLOSE_CART_MODAL);

export const defaultState = {
  showCartModal: false,
  button: {
    loading: false,
  },
};

export const reducer = handleActions(
  {
    [OPEN_CART_MODAL]: (state) => ({
      ...state,
      showCartModal: true,
    }),
    [CLOSE_CART_MODAL]: (state) => ({
      ...state,
      showCartModal: false,
    }),
    [combineActions(ADD_ITEM, SIGN_IN, SIGN_UP, SUBMIT_ORDER)]: (state) => ({
      ...state,
      button: {
        loading: true,
      },
    }),
    [combineActions(
      ADD_ITEM_SUCCESS,
      ADD_ITEM_FAILURE,
      SIGN_IN_SUCCESS,
      SIGN_IN_FAILURE,
      SIGN_UP_SUCCESS,
      SIGN_UP_FAILURE,
      SUBMIT_ORDER_SUCCESS,
      SUBMIT_ORDER_FAILURE,
    )]: (state) => ({
      ...state,
      button: {
        loading: false,
      },
    }),
  },
  defaultState,
);
