import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'REDUCERS/ORDER';

export const GET_ORDER_RECEIPT = `${PREFIX}/GET_ORDER_RECEIPT`;
export const GET_ORDER_RECEIPT_SUCCESS = `${PREFIX}/GET_ORDER_RECEIPT_SUCCESS`;
export const GET_ORDER_RECEIPT_FAILURE = `${PREFIX}/GET_ORDER_RECEIPT_FAILURE`;
export const getOrderReceipt = createAction(GET_ORDER_RECEIPT);
export const getOrderReceiptSuccess = createAction(GET_ORDER_RECEIPT_SUCCESS);
export const getOrderReceiptFailure = createAction(GET_ORDER_RECEIPT_FAILURE);

export const defaultState = {
  _id: '',
  id: '',
  total: 0,
  subtotal: 0,
  tax: 0,
  discount: 0,
  tips: 0,
  state: null,
  timeType: 'asap',
  customerAddress: null,
  title: null,
  locationId: '',
  orderTypeId: '',
  customerId: '',
  createdAt: '',
  updatedAt: '',
  payment: [],
  location: {
    _id: '',
    id: '',
    slug: '',
    name: '',
    phone: '',
    address1: '',
    address2: null,
    city: '',
    state: '',
    zip: '',
    latitude: 0,
    longitude: 0,
    businessId: '',
    acceptOrder: false,
    logo: null,
    banner: null,
    timezone: null,
    email: null,
    installed: true,
    openTime: '',
    closeTime: '',
    createdAt: '',
    updatedAt: '',
  },
  items: [],
  customer: {
    _id: '',
    id: null,
    firstName: null,
    lastName: null,
    businessId: '',
    dialCode: '+886',
    phone: '',
    email: null,
  },
  error: false,
};

export const reducer = handleActions(
  {
    [GET_ORDER_RECEIPT_SUCCESS]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [GET_ORDER_RECEIPT_FAILURE]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        error: true,
      };
    },
  },
  defaultState,
);
