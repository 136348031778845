import React from 'react';
import { connect } from 'react-redux';
import SignUpForm from '../../components/signUpForm';
import BasicLayout from '../../components/basicLayout';
import { signUp } from '../../reducers/auth';

const SignUp = (props) => {
  const onSubmit = (form) => props.signUp(form);

  return (
    <BasicLayout style={{ marginTop: '100px' }}>
      <SignUpForm onSubmit={onSubmit} referrer={props.referrer} ui={props.ui} />
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    referrer: state.router.location.search,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (form) => dispatch(signUp(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
