import { createAction, handleActions } from 'redux-actions';
import storage from '../lib/storage';

const PREFIX = 'REDUCERS/ORDER';

export const OPEN_ORDER_SUCCESS = `${PREFIX}/OPEN_ORDER_SUCCESS`;
export const OPEN_ORDER_FAILURE = `${PREFIX}/OPEN_ORDER_FAILURE`;

export const openOrderSuccess = createAction(OPEN_ORDER_SUCCESS);
export const openOrderFailed = createAction(OPEN_ORDER_FAILURE);

export const GET_ORDER = `${PREFIX}/GET_ORDER`;
export const GET_ORDER_SUCCESS = `${PREFIX}/GET_ORDER_SUCCESS`;
export const GET_ORDER_FAILURE = `${PREFIX}/GET_ORDER_FAILURE`;
export const getOrderSuccess = createAction(GET_ORDER_SUCCESS);
export const getOrderFailure = createAction(GET_ORDER_FAILURE);

export const ADD_ITEM = `${PREFIX}/ADD_ITEM`;
export const ADD_ITEM_SUCCESS = `${PREFIX}/ADD_ITEM_SUCCESS`;
export const ADD_ITEM_FAILURE = `${PREFIX}/ADD_ITEM_FAILURE`;
export const addItem = createAction(ADD_ITEM);
export const addItemSuccess = createAction(ADD_ITEM_SUCCESS);
export const addItemFailure = createAction(ADD_ITEM_FAILURE);

export const CANCEL_ORDER = `${PREFIX}/CANCEL`;
export const CANCEL_ORDER_SUCCESS = `${PREFIX}/CANCEL_SUCCESS`;
export const CANCEL_ORDER_FAILURE = `${PREFIX}/CANCEL_FAILURE`;
export const cancelOrder = createAction(CANCEL_ORDER);
export const cancelOrderSuccess = createAction(CANCEL_ORDER_SUCCESS);
export const cancelOrderFailure = createAction(CANCEL_ORDER_FAILURE);

export const SET_NEW_ITEM_QUANTITY = `${PREFIX}/SET_NEW_ITEM_QUANTITY`;
export const SET_NEW_ITEM = `${PREFIX}/SET_NEW_ITEM`;
export const RESET_NEW_ITEM = `${PREFIX}/RESET_NEW_ITEM`;
export const SET_NEW_ITEM_VARIATION = `${PREFIX}/SET_NEW_ITEM_VARIATION`;
export const SELECT_NEW_ITEM_MODIFIER = `${PREFIX}/SELECT_NEW_ITEM_MODIFIER`;
export const UNSELECT_NEW_ITEM_MODIFIER = `${PREFIX}/UNSELECT_NEW_ITEM_MODIFIER`;

export const setNewItemQuantity = createAction(SET_NEW_ITEM_QUANTITY);
export const setNewItem = createAction(SET_NEW_ITEM);
export const resetNewItem = createAction(RESET_NEW_ITEM);
export const setNewItemVariant = createAction(SET_NEW_ITEM_VARIATION);
export const selectNewItemModifier = createAction(SELECT_NEW_ITEM_MODIFIER);
export const unselectNewItemModifier = createAction(UNSELECT_NEW_ITEM_MODIFIER);

export const SUBMIT_ORDER = `${PREFIX}/SUBMIT_ORDER`;
export const SUBMIT_ORDER_SUCCESS = `${PREFIX}/SUBMIT_SUCCESS`;
export const SUBMIT_ORDER_FAILURE = `${PREFIX}/SUBMIT_FAILURE`;
export const submitOrder = createAction(SUBMIT_ORDER);
export const submitOrderSuccess = createAction(SUBMIT_ORDER_SUCCESS);
export const submitOrderFailure = createAction(SUBMIT_ORDER_FAILURE);

export const SET_ORDER_CUSTOMER = `${PREFIX}/SET_ORDER_CUSTOMER`;
export const setOrderCustomer = createAction(SET_ORDER_CUSTOMER);

export const RESET_ORDER = `${PREFIX}/RESET`;
export const resetOrder = createAction(RESET_ORDER);

const initNewItem = () => ({
  _id: null,
  id: null,
  quantity: 1,
  name: '',
  variant: {},
  modifiers: [],
  hasRequiredModifier: false,
});

const getInitialState = () => {
  return {
    _id: storage.getKey('orderId') || null,
    total: 0,
    subtotal: 0,
    tax: 0,
    discount: 0,
    newItem: initNewItem(), // as helper state for holding new item which will be added to the items
    items: [],
    error: false,
    loading: false,
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  };
};

export const defaultState = getInitialState();

export const reducer = handleActions(
  {
    /* [OPEN_ORDER_SUCCESS]: (state, { payload }) => {
      console.log(payload);
      return {
        ...state,
        ...payload,
        _id: payload._id,
      };
    }, */
    [OPEN_ORDER_FAILURE]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        error: true,
      };
    },
    [SET_NEW_ITEM]: (state, { payload }) => {
      return {
        ...state,
        newItem: { ...initNewItem(), ...payload },
      };
    },
    [RESET_NEW_ITEM]: (state) => {
      return {
        ...state,
        newItem: { ...initNewItem() },
      };
    },
    [SET_NEW_ITEM_VARIATION]: (state, { payload }) => {
      return {
        ...state,
        newItem: { ...state.newItem, variant: payload },
      };
    },
    [SET_NEW_ITEM_QUANTITY]: (state, { payload }) => {
      return {
        ...state,
        newItem: { ...state.newItem, quantity: payload },
      };
    },
    [SELECT_NEW_ITEM_MODIFIER]: (state, { payload }) => {
      return {
        ...state,
        newItem: { ...state.newItem, modifiers: state.newItem.modifiers.concat(payload) },
      };
    },
    [UNSELECT_NEW_ITEM_MODIFIER]: (state, { payload }) => {
      return {
        ...state,
        newItem: {
          ...state.newItem,
          modifiers: state.newItem.modifiers.filter((mod) => payload.id !== mod.id),
        },
      };
    },
    [GET_ORDER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [OPEN_ORDER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        _id: payload,
      };
    },
    [ADD_ITEM_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        newItem: initNewItem(),
        items: payload.items,
      };
    },
    [CANCEL_ORDER_SUCCESS]: (state) => {
      return {
        ...state,
        ...getInitialState(),
      };
    },
    [SET_ORDER_CUSTOMER]: (state, { payload }) => {
      return {
        ...state,
        customer: { ...state.customer, ...payload },
      };
    },
    [RESET_ORDER]: () => {
      return {
        ...getInitialState(),
        _id: null,
      };
    },
  },
  defaultState,
);
