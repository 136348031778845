import React from 'react';
import { connect } from 'react-redux';
import LocationHeader from '../../components/locationHeader';
import Inventory from '../../components/inventory';
import LocationBasicLayout from '../../components/locationBasicLayout';
import { fetchLocationBySlug } from '../../reducers/location';
import Loading from '../../components/loading';
import LocationNav from '../../components/locationNav';
import ItemModal from '../../components/itemModal';

class Merchant extends React.Component {
  componentDidMount() {
    const { slug } = this.props.match.params;
    this.props.getLocation(slug);
  }

  render() {
    const {
      location, item, order, auth,
    } = this.props;

    const content = location.loading ? <Loading /> : (
      <LocationBasicLayout>
        <LocationNav location={location} order={order} showSignIn={!auth._id} />
        <LocationHeader location={location} />
        <div>
          <Inventory />
        </div>
        {item.selected && <ItemModal />}
      </LocationBasicLayout>
    );
    return location.error ? (<div>Error UI here</div>) : content;
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.location,
    order: state.order,
    item: state.item,
    ui: state.ui,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: (slug) => {
      dispatch(fetchLocationBySlug(slug));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
