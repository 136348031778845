import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {
  Switch, Route, Redirect,
} from 'react-router';
import store from './store';
import history from './store/history';
import * as PATH from './constants/path';
import Home from './pages/home';
import Merchant from './pages/location';
import Checkout from './pages/checkout';
import Confirmation from './pages/confirmation';
import Profile from './pages/profile';
import SignIn from './pages/signIn';
import SignUp from './pages/signUp';
import RequestCode from './pages/requestCode';
import IntlWrapper from './lib/locale/intlWrapper';

const App = () => {
  const { router } = store.getState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.location]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlWrapper>
          <Switch>
            <Route exact path={PATH.HOME} component={Home} />
            <Route exact path={PATH.PROFILE} component={Profile} />
            <Route exact path={PATH.CHECKOUT} component={Checkout} />
            <Route exact path={PATH.CONFIRMATION} component={Confirmation} />
            <Route exact path={PATH.SIGN_IN} component={SignIn} />
            <Route exact path={PATH.SIGN_UP} component={SignUp} />
            <Route exact path={PATH.REQUEST_CODE} component={RequestCode} />
            <Route exact path={PATH.MERCHANT} component={Merchant} />
            <Redirect from="/" to={PATH.HOME} />
          </Switch>
        </IntlWrapper>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
