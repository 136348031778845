import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Image,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import style from './sign-up-form.module.scss';

import 'react-phone-input-2/lib/semantic-ui.css';
import { SIGN_IN } from '../../constants/path';
import AuthLogo from '../../assets/img/auth_logo.svg';
import FormattedText from '../../lib/locale/formattedText';

const SignUpForm = ({ onSubmit, referrer, ui }) => {
  const [phoneData, setPhone] = useState({ dialCode: '+62', rawPhone: '', phone: '62' });
  const handleChange = (value, data) => {
    setPhone({ dialCode: `+${data.dialCode}`, rawPhone: value.slice(data.dialCode.length), phone: value });
  };
  const handleSubmit = () => onSubmit(phoneData);

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="blue" textAlign="center">
          <Image src={AuthLogo} style={{ maxHeight: '150px', width: 'auto' }} />
        </Header>
        <Form size="large" onSubmit={handleSubmit}>
          <Segment className="middle-aligned">
            <h2><FormattedText id="sign-up.title" defaultMessage="Register" /></h2>
            <div className={style['form-description']}>
              <FormattedText id="sign-up.description" defaultMessage="Enter your mobile number and we will send you a verification code valid for 10 minutes." />
            </div>
            <PhoneInput
              country="id"
              dropdownStyle={{ textAlign: 'left' }}
              containerStyle={{ marginBottom: '10px' }}
              inputStyle={{ paddingLeft: '48px' }}
              value={phoneData.phone}
              onChange={handleChange}
            />
            <Button color="blue" fluid size="large" loading={ui.button.loading}>
              <FormattedText id="button.sign-up" defaultMessage="Sign up" />
            </Button>
          </Segment>
        </Form>
        <Message>
          <FormattedText id="button.client-question" defaultMessage="Already a customer?" />
          <Link to={referrer ? `${SIGN_IN}${referrer}` : `${SIGN_IN}`} style={{ paddingLeft: '3px' }}>
            <FormattedText id="sign-up.login-link" defaultMessage="Login here" />
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default SignUpForm;
