import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorMessage = ({ message }) => {
  const style = {
    position: 'absolute',
    top: 0,
    width: '100%',
    textAlign: 'center',
  };
  return (
    <Message color="red" style={style}>{message}</Message>
  );
};

export default ErrorMessage;
