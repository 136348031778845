import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'REDUCERS/ITEM';

export const GET_ITEM = `${PREFIX}/GET_ITEM`;
export const SELECT_ITEM = `${PREFIX}/SELECT_ITEM`;
export const DESELECT_ITEM = `${PREFIX}/DESELECT_ITEM`;
export const GET_ITEM_SUCCESS = `${PREFIX}/GET_ITEM_SUCCESS`;
export const GET_ITEM_ERROR = `${PREFIX}/GET_ITEM_ERROR`;

export const selectItem = createAction(SELECT_ITEM);
export const deselectItem = createAction(DESELECT_ITEM);
export const fetchItem = createAction(GET_ITEM);
export const fetchItemSuccess = createAction(GET_ITEM_SUCCESS);
export const fetchItemError = createAction(GET_ITEM_ERROR);

const getInitialState = () => {
  return {
    loading: false,
    error: false,
    selected: false,
    locationId: null,
    itemId: null,
    selectedItem: {
      _id: null,
      id: null,
      name: '',
      price: 0,
      image: '',
      imageAlt: '',
      variants: [],
      modifierGroups: null,
    },
  };
};

export const defaultState = getInitialState();

export const reducer = handleActions(
  {
    [GET_ITEM]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: true,
      };
    },
    [GET_ITEM_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        error: false,
        loading: false,
        selectedItem: payload,
      };
    },
    [GET_ITEM_ERROR]: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    [SELECT_ITEM]: (state, { payload }) => {
      return {
        ...state,
        selectedItem: payload,
        selected: true,
      };
    },
    [DESELECT_ITEM]: () => {
      return {
        ...getInitialState(),
      };
    },
  },
  defaultState,
);
