import React from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import FormattedText from '../../lib/locale/formattedText';

const dayList = [
  { key: 'sun-0', text: 'Sunday', value: 0 },
  { key: 'mon-1', text: 'Monday', value: 1 },
  { key: 'tue-2', text: 'Tuesday', value: 2 },
  { key: 'wed-3', text: 'Wednesday', value: 3 },
  { key: 'thu-4', text: 'Thursday', value: 4 },
  { key: 'fri-5', text: 'Friday', value: 5 },
  { key: 'sat-6', text: 'Saturday', value: 6 },
];

const OrderTimeForm = () => {
  const intl = useIntl();
  const now = intl.formatMessage({ id: 'checkout-form.now', defaultMessage: 'Now' });
  const schedule = intl.formatMessage({ id: 'checkout-form.schedule', defaultMessage: 'Schedule' });

  const TIME_SCHEDULED = 'scheduled';
  const TIME_ASAP = 'asap';
  const [timeType, setTimeType] = React.useState(TIME_ASAP);
  const handleChange = (event) => {
    setTimeType(event.target.value);
  };

  const dayChanged = () => {

  };
  return (
    <Segment size="massive">
      <Header as="label">
        <FormattedText id="checkout-form.time" defaultMessage="When" />
      </Header>
      <Form size="big">
        <Form.Group grouped>
          <Form.Field
            defaultChecked
            onChange={handleChange}
            value={TIME_ASAP}
            label={now}
            control="input"
            type="radio"
            name="orderTime"
          />
          <Form.Field
            onChange={handleChange}
            value={TIME_SCHEDULED}
            label={schedule}
            control="input"
            type="radio"
            name="orderTime"
          />
        </Form.Group>
        <Form.Group inline widths="equal">
          <Form.Field>
            <Form.Select
              fluid
              disabled={timeType === TIME_ASAP}
              label="Day"
              options={dayList}
              placeholder="Day"
              onChange={(event, result) => dayChanged(result.value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              disabled={timeType === TIME_ASAP}
              label="Time"
              options={dayList}
              placeholder="Time"
              onChange={(event, result) => dayChanged(result.value)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
};

export default OrderTimeForm;
