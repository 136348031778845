import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Cart from '../cart';
import FormattedText from '../../lib/locale/formattedText';

const CartModal = ({ closeModal }) => {
  const history = useHistory();
  const { slug } = useParams();
  const intl = useIntl();

  const goToCheckout = () => {
    const url = `/${slug}/checkout`;
    history.push(url);
  };

  const { order, location } = useSelector((state) => state);

  return (
    <Modal open closeIcon size="large" onClose={closeModal}>
      <Modal.Header><FormattedText id="cart-modal.title" defaultMessage="Cart" /></Modal.Header>
      <Modal.Content scrolling>
        <Cart order={order} closeModal={closeModal} currency={location.currency} />
      </Modal.Content>
      <Modal.Actions onClick={() => closeModal()}>
        <Button
          content={intl.formatMessage({ id: 'cart-modal.close', defaultMessage: 'Close' })}
          labelPosition="left"
          icon="close"
          onClick={closeModal}
          negative
        />
        <Button
          disabled={order.items.length < 1}
          content={intl.formatMessage({ id: 'cart-modal.checkout', defaultMessage: 'Checkout' })}
          labelPosition="left"
          icon="cart"
          positive
          onClick={goToCheckout}
        />
      </Modal.Actions>
    </Modal>
  );
};
export default CartModal;
