import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import FormattedText from '../../lib/locale/formattedText';
import { submitOrder } from '../../reducers/order';

const ButtonPlaceOrder = ({ disabled, loading: buttonLoading = false }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const checkout = () => {
    // TODO: pay order
    dispatch(submitOrder());
    setLoading(true);
  };

  return (
    <Button
      onClick={checkout}
      disabled={disabled || loading}
      primary
      size="huge"
      fluid
      loading={buttonLoading}
    >
      {
        disabled
          ? <FormattedText id="button.checkout-incomplete" defaultMessage="Incomplete info" />
          : <FormattedText id="button.checkout-complete" defaultMessage="Place order" />
      }
    </Button>
  );
};

export default ButtonPlaceOrder;
