import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUser } from '../../reducers/auth';

const AuthLayout = ({ children, redirectURL }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifyUser(redirectURL));
  }, [auth._id, dispatch, redirectURL]);

  return (auth._id && <div>{children}</div>);
};

export default AuthLayout;
