import React from 'react';
import { Select } from 'semantic-ui-react';

const VariantSelector = ({ variants, handleVariantChange, defaultVariant }) => {
  return (
    <Select
      placeholder="Select variation"
      onChange={handleVariantChange}
      value={defaultVariant}
      options={variants.map((variant) => ({
        key: variant._id,
        text: variant.name,
        value: variant._id,
      }))}
    />
  );
};

export default VariantSelector;
