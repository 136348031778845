import React from 'react';
import FormattedPrice from '../../lib/locale/formattedPrice';

/**
 * Get item price from variant
 * @param {object} item
 * @returns {{min: number, max: number}}
 */
const getItemPrice = (item) => {
  const prices = item.variants.map((variant) => variant.price);
  return { min: Math.min(...prices), max: Math.max(...prices) };
};

const ItemCard = ({ item, handleClick, currency }) => {
  return (
    <div
      tabIndex="0"
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleClick(item);
        }
      }}
      onClick={() => handleClick(item)}
      className="flex w-full h-32 border border-grey-light"
    >
      {item.image && (
        <img
          loading="lazy"
          className="w-32 h-32 lg:h-auto flex-none bg-cover text-center overflow-hidden p-1"
          style={{ imageOrientation: 'from-image', objectFit: 'cover' }}
          src={item.image}
          alt=""
        />
      )}
      <div
        className="w-full bg-white p-3 sm:p-4 flex flex-col justify-between leading-normal overflow-y-hidden"
      >
        <div className="text-sm sm:text-base sm:mb-8">
          <div className="flex flex-shrink justify-between mb-2">
            <div className="pr-3 clamp-1 text-black font-semibold clamp-1">
              {item.name}
            </div>
            {getItemPrice(item).min > 0 && (
            <div className="text-gray-800 font-light">
              <FormattedPrice value={getItemPrice(item).min} currency={currency} />
            </div>
            )}
          </div>
          <div className="text-grey-darker clamp-2">{item.description}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
