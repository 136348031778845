import React, { useEffect, useState } from 'react';
import {
  Header,
  Container,
  Grid,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import LocationNav from '../../components/locationNav';
import LocationBasicLayout from '../../components/locationBasicLayout';
import { fetchLocationBySlug } from '../../reducers/location';
import Cart from '../../components/cart';
import CustomerInfoForm from '../../components/customerInfoForm';
import OrderTimeForm from '../../components/orderTimeForm';
import PaymentForm from '../../components/paymentForm';
import ButtonPlaceOrder from '../../components/buttonPlaceOrder';
import { SIGN_IN } from '../../constants/path';
import FormattedText from '../../lib/locale/formattedText';
import AuthLayout from '../auth';
import FormattedPrice from '../../lib/locale/formattedPrice';

const Checkout = () => {
  const { slug } = useParams();
  const {
    location,
    order,
    router,
    auth,
    ui,
  } = useSelector((state) => state);
  const redirectURL = `${SIGN_IN}?r=${router.location.pathname}&order=${order._id}&business=${location.businessId}`;
  const dispatch = useDispatch();

  // used to mark form state
  const [formState, setFormState] = useState({ customer: true, payment: true });
  // used as a flag for enable/disable pay button
  const [canCheckout, setCanCheckout] = useState(false);

  const setCustomerFormState = (valid) => {
    setFormState((state) => ({ ...state, customer: valid }));
  };

  useEffect(() => {
    dispatch(fetchLocationBySlug(slug));
  }, [slug, dispatch]);

  // for pay button
  useEffect(() => {
    const validFormState = Object.values(formState).filter((val) => val === false).length === 0;
    setCanCheckout(validFormState);
  }, [formState]);

  return (
    <AuthLayout redirectURL={redirectURL}>
      <LocationBasicLayout>
        <LocationNav location={location} order={order} showSignIn={false} />
        <Container>
          <div
            style={{
              fontSize: '24px',
              padding: '15px',
              textAlign: 'center',
              marginTop: '1em',
            }}
          >
            <FormattedText id="checkout-form.almost-there" defaultMessage="You are almost there!" />
          </div>
          <Grid style={{ marginTop: '1em' }}>
            <Grid.Column mobile={16} tablet={10} computer={11}>
              <Segment.Group piled style={{ border: 0, boxShadow: 0 }}>
                <CustomerInfoForm
                  customer={auth}
                  setCustomerFormState={setCustomerFormState}
                />
                <OrderTimeForm />
                <PaymentForm />
              </Segment.Group>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={5}>
              <div>
                <Header size="large">
                  <FormattedText id="checkout-form.order" defaultMessage="Order from:" />
                  &nbsp;
                  <Link to={`/${location.slug}`}>{location.name}</Link>
                </Header>
              </div>
              <Divider />
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Cart order={order} currency={location.currency} />
              </div>
              <Divider />
              <div>
                <div className="flex justify-between">
                  <div>
                    <FormattedText
                      id="checkout-form.subtotal"
                      defaultMessage="Subtotal - {items, plural, =0 {no items} one {# item} other {# items}}"
                      values={{ items: order.items.length }}
                    />
                  </div>
                  <div>
                    <FormattedPrice value={order.subtotal} currency={location.currency} />
                  </div>
                </div>
                <div style={{ fontSize: '18px', fontWeight: '700', marginTop: '20px' }}>
                  <div className="flex justify-between">
                    <div>
                      <FormattedText id="checkout-form.total" defaultMessage="Total" />
                    </div>
                    <div>
                      <FormattedPrice value={order.total} currency={location.currency} />
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div>
                <ButtonPlaceOrder disabled={!canCheckout} loading={ui.button.loading} />
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </LocationBasicLayout>
    </AuthLayout>
  );
};

export default Checkout;
