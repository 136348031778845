import { put, call, takeLatest } from '@redux-saga/core/effects';
import { fetchLocationBySlug } from '../constants/api/locations';
import {
  fetchLocationBySlugSuccess,
  GET_LOCATION_BY_SLUG,
  fetchLocationBySlugError,
} from '../reducers/location';
import storage from '../lib/storage';
import { getOrderSuccess, resetOrder } from '../reducers/order';
import { getOrder } from '../constants/api/orders';

const getOrderId = () => storage.getKey('orderId');

function* getLocationFromSlug({ payload }) {
  try {
    const { data: location } = yield call(fetchLocationBySlug, payload);
    storage.setKey('locationId', location._id);
    storage.setKey('slug', location.slug);
    yield put(fetchLocationBySlugSuccess(location));

    const orderId = getOrderId();
    if (orderId) {
      const { data: order } = yield call(getOrder, location._id, orderId);

      // automatically remove invalid order ID
      // currently is used when navigating from receipt page to menu page
      if (order.state && order.state !== 'open') {
        storage.deleteKey('orderId');
        yield put(resetOrder());
        return;
      }

      yield put(getOrderSuccess(order));
    }
  } catch (e) {
    yield put(fetchLocationBySlugError(e));
  }
}

export function* watchLocation() {
  yield takeLatest(GET_LOCATION_BY_SLUG, getLocationFromSlug);
}
