import React from 'react';
import {
  Header, Container, Segment, Grid, Image,
} from 'semantic-ui-react';
import Sticky from 'react-stickynode';
import ButtonSignIn from '../buttonSignIn';
import ButtonCart from '../button-cart';
import HomeLogo from '../../assets/img/home_logo.svg';

const LocationNav = ({ location, order, showSignIn = true }) => {
  const queryParams = `?business=${location.businessId}`;
  return (
    <Sticky enabled top={0} innerZ={10}>
      <Segment style={{ margin: '0' }}>
        <Container fluid>
          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column>
              <Header>
                <Image src={HomeLogo} style={{ height: '35px', width: '35px' }} />
                <div
                  style={{
                    lineHeight: '35px',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                    marginLeft: '10px',
                  }}
                >
                  {location.name}
                </div>
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <ButtonCart order={order} />
              {showSignIn && <ButtonSignIn queryParams={queryParams} />}
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    </Sticky>
  );
};

export default LocationNav;
