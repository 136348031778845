import React from 'react';
import {
  Checkbox,
  Form, Header, Label, Radio, Segment,
} from 'semantic-ui-react';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';

const ModifierGroup = ({
  modGroup, handleModSelect, handleUnselectMod, selectedMods,
}) => {
  const modifiers = selectedMods
    .filter((mod) => mod.modifierGroupId === modGroup._id)
    .map((mod) => mod.id);

  const selectMod = ({ checked }, modifier) => {
    if (checked) {
      handleModSelect(modifier);
      return;
    }
    handleUnselectMod(modifier);
  };

  const radioClicked = (modifier) => {
    const mods = selectedMods.filter((mod) => mod.modifierGroupId === modifier.modifierGroupId);
    // because this modifier group only allow 1 mod
    // we unselect the selected modifier
    mods.map((m) => handleUnselectMod(m));

    handleModSelect(modifier);
  };

  const isDisabled = (modifierId) => {
    if (modGroup.maximum === 0) {
      return false;
    }
    if (modGroup.maximum === 1 && modGroup.minimum === 1) {
      return false;
    }
    const disabled = modifiers.length === modGroup.maximum;
    return disabled && !modifiers.includes(modifierId);
  };

  const getRequirementText = () => {
    const text = [];
    if (modGroup.minimum > 1) {
      text.push(`Min. ${modGroup.minimum}`);
    }
    if (modGroup.maximum > 0) {
      text.push(`Max. ${modGroup.maximum}`);
    }
    if (modGroup.minimum === 1 && modGroup.maximum === 1) {
      return null;
    }
    return text.join(' ');
  };

  const getLabel = ({ name, price }) => {
    if (price > 0) {
      return `${name} - ${price}`;
    }
    return name;
  };

  return (
    <Segment key={modGroup.id}>
      <Header size="medium" as="h3">
        {modGroup.minimum > 0 && (
          <Label ribbon color="red" size="tiny">
            Required
          </Label>
        )}
        {modGroup.name}
        <HeaderSubHeader>{getRequirementText()}</HeaderSubHeader>
      </Header>
      <div>
        <Form.Group grouped>
          {modGroup.modifiers.map((modifier) => {
            return (
              <Form.Field key={modifier.id}>
                {(modGroup.minimum === 1 && modGroup.maximum === 1)
                  ? (
                    <Radio
                      label={getLabel(modifier)}
                      name={modGroup.id}
                      value={modifier.id}
                      checked={modifiers.includes(modifier.id)}
                      onChange={() => radioClicked(modifier)}
                    />
                  )
                  : (
                    <Checkbox
                      disabled={isDisabled(modifier.id)}
                      label={getLabel(modifier)}
                      name="modifier"
                      radio={modGroup.minimum === 1 && modGroup.maximum === 1}
                      value={modifier.id}
                      onChange={(event, data) => selectMod(data, modifier)}
                    />
                  )}

              </Form.Field>
            );
          })}
        </Form.Group>
      </div>
    </Segment>
  );
};

ModifierGroup.defaultProps = {
  modGroup: {},
  handleModSelect: () => {
  },
  handleUnselectMod: () => {
  },
};

export default ModifierGroup;
