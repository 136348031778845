import {
  call,
  select,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ADD_ITEM,
  addItemFailure,
  addItemSuccess,
  CANCEL_ORDER,
  cancelOrderFailure,
  cancelOrderSuccess,
  openOrderSuccess,
  resetNewItem,
  SUBMIT_ORDER,
  submitOrderFailure,
  submitOrderSuccess,
} from '../reducers/order';
import storage from '../lib/storage';
import {
  openOrder, addItem, cancelOrder, submitOrder as handleSubmitOrder,
} from '../constants/api/orders';
import { deselectItem } from '../reducers/item';

const getLocationId = () => storage.getKey('locationId');
const getOrderId = () => storage.getKey('orderId');

function* addItemToOrder() {
  try {
    const locationId = getLocationId();
    let orderId = getOrderId();

    if (!orderId) {
      const { data } = yield call(openOrder, locationId);
      orderId = data.id;
      storage.setKey('orderId', orderId);
      yield put(openOrderSuccess(orderId));
    }
    const { newItem } = yield select((state) => state.order);
    const { data: items } = yield call(addItem, locationId, orderId, newItem);
    yield put(addItemSuccess({ items }));
    yield put(resetNewItem());
    yield put(deselectItem());
  } catch (err) {
    yield put(addItemFailure());
  }
}

function* clearCart() {
  try {
    const { slug } = yield select((state) => state.location);
    yield call(cancelOrder, getLocationId(), getOrderId());
    yield put(cancelOrderSuccess());
    storage.deleteKey('orderId');
    yield put(push(`/${slug}`));
  } catch (err) {
    yield put(cancelOrderFailure());
  }
}

function* submitOrder() {
  try {
    const { order, auth } = yield select((state) => state);
    const payload = {
      customer: { _id: auth._id, dialCode: auth.dialCode, ...order.customer },
      timeType: 'asap', // TODO: should come from the time options
    };
    yield call(handleSubmitOrder, getLocationId(), getOrderId(), payload);
    const { slug } = yield select((state) => state.location);
    yield put(submitOrderSuccess());
    yield put(push(`/${slug}/confirmation/${getLocationId()}/orders/${getOrderId()}`));
    storage.deleteKey('orderId');
  } catch (err) {
    yield put(submitOrderFailure());
  }
}

export function* watchOrder() {
  yield takeLatest(ADD_ITEM, addItemToOrder);
  yield takeLatest(CANCEL_ORDER, clearCart);
  yield takeLatest(SUBMIT_ORDER, submitOrder);
}
