import axios from 'axios';
import storage from './storage';

const http = (
  url,
  { method = 'GET', data = undefined, auth = true },
) => {
  return axios({
    url,
    baseURL: process.env.REACT_APP_END_POINT,
    method,
    data,
    headers: getHeaders(auth),
    withCredentials: true,
  });
};

const getHeaders = (auth) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (auth) {
    headers.Authorization = `bearer ${storage.getKey('authToken')}`;
  }

  return headers;
};

const get = (url, opts = {}) => http(url, { ...opts });
const post = (url, opts = {}) => http(url, { method: 'POST', ...opts });
const put = (url, opts = {}) => http(url, { method: 'PUT', ...opts });
const deleteData = (url, opts = {}) => http(url, { method: 'DELETE', ...opts });

export default {
  get,
  post,
  put,
  delete: deleteData,
};
