import {
  call,
  put,
  takeLatest,
  select,
} from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import {
  signInAPI, codeAPI, signUpAPI, verifyCustomerAPI,
} from '../constants/api/auth';
import {
  SIGN_UP,
  SIGN_IN,
  SEND_CODE,
  VERIFY_USER,
  signUpFailure,
  signUpSuccess,
  signInFailure,
  sendCodeFailure,
  sendCodeSuccess,
  verifyUserSuccess,
} from '../reducers/auth';
import { REQUEST_CODE, HOME } from '../constants/path';

function* getURLParams() {
  const { router } = yield select((state) => state);
  return router.location.search;
}

function* authMobile({ payload }) {
  const { dialCode, rawPhone: phone } = payload;
  const referrer = yield call(getURLParams);
  try {
    yield call(signInAPI, { dialCode, phone });
    yield put(push(referrer ? `${REQUEST_CODE}${referrer}&dialCode=${dialCode}&phone=${phone}` : REQUEST_CODE));
  } catch (e) {
    yield put(signInFailure(e.response.data.message));
  }
}

function* signUpCustomer({ payload }) {
  const { dialCode, rawPhone: phone } = payload;
  const { _id: orderId } = yield select((state) => state.order);
  let { businessId } = yield select((state) => state.location);

  // patch for page refresh missing the businessId
  if (!businessId) {
    const referrer = yield call(getURLParams);
    businessId = new URLSearchParams(referrer).get('business');
  }

  const referrer = yield call(getURLParams);
  try {
    const { data: { data: { user } } } = yield call(
      signUpAPI,
      { dialCode, phone },
      orderId,
      businessId,
    );
    yield put(signUpSuccess(user));
    yield put(push(referrer ? `${REQUEST_CODE}${referrer}&dialCode=${dialCode}&phone=${phone}` : REQUEST_CODE));
  } catch (e) {
    yield put(signUpFailure(e.response.data.message));
  }
}

function* sendCode({ payload }) {
  const referrer = yield call(getURLParams);
  const urlParams = new URLSearchParams(referrer);
  // the + sign in dialCode replaced by a white space...
  const dialCode = `+${String(urlParams.get('dialCode')).replace(' ', '')}`;
  const phone = urlParams.get('phone');

  try {
    const { data: { data: { user } } } = yield call(codeAPI, { ...payload, dialCode, phone });
    yield put(sendCodeSuccess(user));
    const { slug } = yield select((state) => state.location);
    yield put(push(`/${slug}/checkout` || HOME));
  } catch (e) {
    yield put(sendCodeFailure());
  }
}

function* checkUser({ payload }) {
  try {
    const { data: { data: customer } } = yield call(verifyCustomerAPI);
    yield put(verifyUserSuccess(customer));
  } catch (e) {
    yield put(push(payload));
  }
}

export function* watchAuth() {
  yield takeLatest(SIGN_IN, authMobile);
  yield takeLatest(SIGN_UP, signUpCustomer);
  yield takeLatest(SEND_CODE, sendCode);
  yield takeLatest(VERIFY_USER, checkUser);
}
