import React from 'react';
import ItemCard from '../itemCard';

const CategoryContainer = ({ category, handleItemClick, currency }) => {
  return (
    <div className="container my-5">
      <div
        className="cursor-pointer flex justify-between sm:text-2xl bg-gray-100 px-4 py-5 shadow"
      >
        <div>{category.name}</div>
      </div>
      <div className="flex content-start flex-wrap">
        {category.items.map((item) => {
          return (
            <div
              key={`${category.id}-${item.id}`}
              className="my-1 w-full md:w-1/2 xl:w-1/3 p-2 cursor-pointer hover:bg-gray-200"
            >
              <ItemCard item={item} handleClick={handleItemClick} currency={currency} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryContainer;
