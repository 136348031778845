import React from 'react';
import styles from './spinner.module.css';

const Spinner = ({ borderColor = 'border-gray-500', widthClass = 'w-12', heightClass = 'h-12' }) => {
  return (
    <div className={`${widthClass} ${heightClass} border-4 ${borderColor} rounded-full ${styles.loader}`} />
  );
};

export default Spinner;
