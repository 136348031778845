import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedText = ({
  id,
  defaultMessage,
  tagName,
  values,
}) => {
  return (
    <FormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      tagName={tagName}
      values={values}
    />
  );
};

export default FormattedText;
