import { createAction, handleActions } from 'redux-actions';
import storage from '../lib/storage';

const PREFIX = 'REDUCERS/MERCHANT';
export const GET_LOCATION_BY_SLUG = `${PREFIX}/GET_LOCATION_BY_SLUG`;
export const GET_LOCATION_BY_SLUG_SUCCESS = `${PREFIX}/GET_LOCATION_BY_SLUG_SUCCESS`;
export const GET_LOCATION_BY_SLUG_ERROR = `${PREFIX}/GET_LOCATION_BY_SLUG_ERROR`;

export const fetchLocationBySlug = createAction(GET_LOCATION_BY_SLUG);
export const fetchLocationBySlugError = createAction(GET_LOCATION_BY_SLUG_ERROR);
export const fetchLocationBySlugSuccess = createAction(GET_LOCATION_BY_SLUG_SUCCESS);

export const defaultState = {
  _id: null,
  id: null,
  name: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  latitude: 0,
  longitude: 0,
  businessId: '',
  acceptOrder: 0,
  logo: null,
  banner: null,
  timezone: null,
  email: '',
  slug: storage.getKey('slug') || null,
  error: false,
  loading: true,
  categories: [],
  currency: 'IDR',
};

export const reducer = handleActions(
  {
    [GET_LOCATION_BY_SLUG_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        error: false,
        loading: false,
      };
    },
    [GET_LOCATION_BY_SLUG_ERROR]: (state) => {
      return {
        state,
        error: true,
        loading: false,
      };
    },
  },
  defaultState,
);
