import React from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import FormattedText from '../../lib/locale/formattedText';

const PaymentForm = () => {
  const intl = useIntl();
  const card = intl.formatMessage({ id: 'checkout-form.card', defaultMessage: 'Card' });
  const cash = intl.formatMessage({ id: 'checkout-form.cash', defaultMessage: 'Cash' });
  return (
    <Segment size="massive">
      <Header>
        <FormattedText id="checkout-form.payment-info" defaultMessage="Payment Info" />
      </Header>
      <Form size="big">
        <Form.Group inline>
          <Form.Field
            disabled
            control="input"
            type="radio"
            name="payment"
            label={card}
            value="card"
          />
          <Form.Field
            defaultChecked
            control="input"
            type="radio"
            name="payment"
            label={cash}
            value="cash"
          />
        </Form.Group>
      </Form>
    </Segment>
  );
};

export default PaymentForm;
