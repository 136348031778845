import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getOrderReceipt } from '../../reducers/receipt';
import BadgeState from '../../components/badgeState';
import Spinner from '../../components/spinner';
import FormattedPrice from '../../lib/locale/formattedPrice';

const ConfirmationPage = () => {
  const dispatch = useDispatch();
  const { receipt } = useSelector((state) => state);
  const { slug, orderId, locationId } = useParams();

  const refreshInterval = 10 * 1000;
  useEffect(() => {
    let init = false;
    if (!init) {
      dispatch(getOrderReceipt({ orderId, locationId }));
      init = true;
    }
    const orderPolling = setInterval(
      () => dispatch(getOrderReceipt({ orderId, locationId })),
      refreshInterval,
    );
    return () => clearInterval(orderPolling);
  }, [dispatch, locationId, orderId, refreshInterval]);

  return (
    <div className="container mx-auto px-2 sm:px-0 md:w-2/3 lg:w-1/2 xl:w-1/3 pb-20 pt-10 md:pt-16">
      {receipt.id
        ? (
          <div className="h-full flex flex-col border border-gray-400 p-4 shadow-lg">
            <div className="text-center">
              <div className="text-3xl font-bold mb-1">
                <Link to={`/${slug}`}>{receipt.location.name}</Link>
              </div>
              <div>{receipt.location.address1}</div>
              <div>{receipt.location.city}</div>
              <div>{receipt.location.phone}</div>
            </div>
            <div className="my-2" />
            <div className="text-center">
              <div className="text-xl font-semibold">
                Order
                {' '}
                {receipt.title}
              </div>
              <div className="mt-3">
                <BadgeState state={receipt.state} />
              </div>
            </div>
            <div className="border-t my-4 border-gray-400" />
            <div className="text-right">
              {new Date(receipt.createdAt).toLocaleString()}
            </div>
            <div>
              <div className="font-bold text-xl">Customer</div>
              <div>
                {[receipt.customer.firstName, receipt.customer.lastName].join(', ')}
                {' '}
              </div>
              <div>{receipt.customer.email}</div>
              <div>
                {receipt.customer.dialCode}
                {receipt.customer.phone}
              </div>
            </div>
            <div className="border-t my-4 border-gray-400" />
            <div>
              <div className="font-bold text-xl">Items</div>
              {receipt.items.map((item) => (
                <div key={item._id} className="flex w-full justify-between">
                  <div>{item.name}</div>
                  <div>
                    <FormattedPrice value={item.price} currency={receipt.location.currency} />
                  </div>
                </div>
              ))}
              <div className="border-t border-dotted my-2 border-gray-400" />
              <div className="flex justify-between">
                <div className="font-semibold">Subtotal:</div>
                <div>
                  <FormattedPrice value={receipt.subtotal} currency={receipt.location.currency} />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold">Total:</div>
                <div>
                  <FormattedPrice value={receipt.total} currency={receipt.location.currency} />
                </div>
              </div>
            </div>
            <div className="border-t my-4 border-gray-400" />
            <div className="font-semibold">
              If you have any concern please call
              {' '}
              {receipt.location.phone}
            </div>
          </div>
        )
        : (
          <div className="h-full flex justify-center">
            <Spinner />
          </div>
        )}
    </div>
  );
};
export default ConfirmationPage;
