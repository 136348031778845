import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CategoryContainer from '../categoryContainer';
import CategoryNav from '../categoryNav';
import { selectItem } from '../../reducers/item';

const buildRefString = (categoryId) => `category-${categoryId}-ref`;

class Inventory extends React.Component {
  handleCategoryClick = (categoryId) => {
    this[buildRefString(categoryId)].current.scrollIntoView();
  };

  handleItemClick = (item) => {
    this.props.selectItem(item);
  };

  buildCategoryRef(categories) {
    categories.forEach((category) => {
      this[buildRefString(category.id)] = React.createRef();
    });
  }

  render() {
    const { categories, currency } = this.props.location;
    this.buildCategoryRef(categories);

    return (
      <div>
        <CategoryNav categories={categories} handleClick={this.handleCategoryClick} />
        <Container>
          {categories.map((category) => {
            return (
              <div
                key={category.id}
                ref={this[buildRefString(category.id)]}
              >
                <CategoryContainer
                  category={category}
                  handleItemClick={this.handleItemClick}
                  currency={currency}
                />
              </div>
            );
          })}
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectItem: (item) => {
      dispatch(selectItem(item));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
