import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import style from './inputQuantity.module.scss';

const InputQuantity = ({ increaseQuantity, decreaseQuantity, quantity }) => {
  return (
    <div className={style.inputQuantity}>
      <Button icon className={style.buttonLeft} onClick={decreaseQuantity}>
        <Icon name="minus" />
      </Button>
      <div>{quantity}</div>
      <Button icon className={style.buttonRight} onClick={increaseQuantity}>
        <Icon name="plus" />
      </Button>
    </div>
  );
};

InputQuantity.defaultProps = {
  quantity: 0,
};

export default InputQuantity;
