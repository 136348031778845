// import {getLocalStorage} from 'RootDir/generic/utils/browser';
const availableLocales = {
  en: 'en',
  id: 'id',
  it: 'it',
};

// get the first language of the browser
const getBrowserLanguage = () => {
  return (navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage;
};

/*
  1. get the browser language
  2. take out the region code (en-Us -> en)
  3. check if it is available
  4. fallback to english if not
*/
const setUserLocale = (browserLanguage) => {
  const languageWithoutRegionCode = browserLanguage.toLowerCase().split(/[_-]+/)[0];
  return availableLocales[browserLanguage]
    || availableLocales[languageWithoutRegionCode]
    || availableLocales.en;
};

const userLocale = setUserLocale(getBrowserLanguage());

export default userLocale;
