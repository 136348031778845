import React from 'react';

const BadgeState = ({ state }) => {
  const baseClass = 'px-3 py-1 text-white uppercase font-semibold';
  const stateColor = {
    open: 'bg-gray-500',
    pending: 'bg-gray-500',
    accepted: 'bg-blue-500',
    ready: 'bg-green-500',
    closed: 'bg-gray-500',
    cancelled: 'bg-yellow-500',
    rejected: 'bg-red-500',
    refunded: 'bg-red-500',
    completed: 'bg-green-500',
  };
  const badgeClass = `${baseClass} ${stateColor[state]}`;
  return (
    <div className={badgeClass}>
      {state}
    </div>
  );
};

export default BadgeState;
