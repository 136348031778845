import React, { useCallback, useEffect, useState } from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';
import FormattedText from '../../lib/locale/formattedText';
import { setOrderCustomer } from '../../reducers/order';

// TODO: need to think about how this
// form validated together with the other forms on the parent (payment, order time, etc)
const CustomerInfoForm = ({ customer, setCustomerFormState }) => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state);
  const [errorState, setErrorState] = useState({
    firstName: null, lastName: null, email: null, phone: null,
  });

  const handleInputChange = (prop, val) => {
    dispatch(setOrderCustomer({ [prop]: val }));
    setErrorState((state) => ({ ...state, ...{ [prop]: order.customer[prop] === '' } }));
  };

  const handleEmailChange = (val) => {
    const isValidEmail = isEmail(val);
    handleInputChange('email', !isValidEmail ? '' : val);
  };

  const handleOnBlur = (inputName) => {
    setErrorState((state) => {
      return { ...state, ...{ [inputName]: !order.customer[inputName] === true } };
    });
  };

  const validateForm = useCallback(() => {
    let validForm = true;
    Object.keys(order.customer).forEach((key) => {
      if (!order.customer[key]) {
        validForm = false;
      }
    });
    // tell parent about customer form validity
    setCustomerFormState(validForm);
  }, [order.customer]);

  useEffect(() => {
    validateForm();
  }, [validateForm, order.customer]);

  // when the customer is logged in, set initial data
  useEffect(() => {
    ['firstName', 'lastName', 'phone', 'email']
      .map((field) => dispatch(setOrderCustomer({ [field]: customer[field] })));
  }, [customer, dispatch]);

  return (
    <Segment basic size="massive">
      <Header>
        <div><FormattedText id="checkout-form.title" defaultMessage="Customer Info" /></div>
      </Header>
      <Form size="big">
        <Form.Group widths="equal">
          <Form.Field error={errorState.firstName}>
            <label htmlFor="inputFirstName">
              <FormattedText id="checkout-form.first-name" defaultMessage="First name" />
              <div className="ui input">
                <input
                  defaultValue={customer.firstName}
                  id="inputFirstName"
                  name="inputFirstName"
                  onBlur={() => handleOnBlur('firstName')}
                  onInput={(event) => handleInputChange('firstName', event.target.value)}
                />
              </div>
            </label>
          </Form.Field>

          <Form.Field error={errorState.lastName}>
            <label htmlFor="inputLastName">
              <FormattedText id="checkout-form.last-name" defaultMessage="Last name" />
              <div className="ui input">
                <input
                  defaultValue={customer.lastName}
                  id="inputLastName"
                  name="inputLastName"
                  onBlur={() => handleOnBlur('lastName')}
                  onInput={(event) => handleInputChange('lastName', event.target.value)}
                />
              </div>
            </label>
          </Form.Field>

        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={errorState.phone}>
            <label htmlFor="inputPhone">
              <FormattedText id="checkout-form.phone" defaultMessage="Phone" />
              <div className="ui labeled input">
                <div className="ui label label">{customer.dialCode}</div>
                <input
                  defaultValue={customer.phone}
                  id="inputPhone"
                  name="inputPhone"
                  type="tel"
                  onBlur={() => handleOnBlur('phone')}
                  onInput={(event) => handleInputChange('phone', event.target.value)}
                />
              </div>
            </label>
          </Form.Field>

          <Form.Field error={errorState.email}>
            <label htmlFor="inputEmail">
              <FormattedText id="checkout-form.email" defaultMessage="Email" />
              <div className="ui input">
                <input
                  defaultValue={customer.email}
                  id="inputEmail"
                  name="inputEmail"
                  type="email"
                  onBlur={() => handleOnBlur('email')}
                  onInput={(event) => handleEmailChange(event.target.value)}
                />
              </div>
            </label>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
};

export default CustomerInfoForm;
