import { createAction, handleActions, combineActions } from 'redux-actions';

const PREFIX = 'REDUCERS/USER';

export const SIGN_IN = `${PREFIX}/SIGN_IN`;
export const SIGN_IN_SUCCESS = `${PREFIX}/SIGN_IN_SUCCESS`;
export const SIGN_IN_FAILURE = `${PREFIX}/SIGN_IN_FAILURE`;

export const SIGN_UP = `${PREFIX}/SIGN_UP`;
export const SIGN_UP_SUCCESS = `${PREFIX}/SIGN_UP_SUCCESS`;
export const SIGN_UP_FAILURE = `${PREFIX}/SIGN_UP_FAILURE`;

export const SEND_CODE = `${PREFIX}/SEND_CODE`;
export const SEND_CODE_SUCCESS = `${PREFIX}/SEND_CODE_SUCCESS`;
export const SEND_CODE_FAILURE = `${PREFIX}/SEND_CODE_FAILURE`;

export const VERIFY_USER = `${PREFIX}/VERIFY`;
export const VERIFY_USER_SUCCESS = `${PREFIX}/VERIFY_SUCCESS`;
export const VERIFY_USER_FAILURE = `${PREFIX}/VERIFY_FAILURE`;

export const signIn = createAction(SIGN_IN);
export const signInSuccess = createAction(SIGN_IN_SUCCESS);
export const signInFailure = createAction(SIGN_IN_FAILURE);

export const signUp = createAction(SIGN_UP);
export const signUpSuccess = createAction(SIGN_UP_SUCCESS);
export const signUpFailure = createAction(SIGN_UP_FAILURE);

export const sendCode = createAction(SEND_CODE);
export const sendCodeSuccess = createAction(SEND_CODE_SUCCESS);
export const sendCodeFailure = createAction(SEND_CODE_FAILURE);

export const verifyUser = createAction(VERIFY_USER);
export const verifyUserSuccess = createAction(VERIFY_USER_SUCCESS);

// TODO: should we create customer state?
const getInitialState = () => {
  return {
    _id: null,
    isVerified: false,
    firstName: null,
    lastName: null,
    phone: '',
    email: '',
  };
};

export const defaultState = getInitialState();

export const reducer = handleActions(
  {
    [SEND_CODE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [SIGN_UP_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [combineActions(SIGN_IN_FAILURE, SIGN_UP_FAILURE)]: () => {
      return {
        ...getInitialState(),
      };
    },
    [VERIFY_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  defaultState,
);
