import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import style from './locationHeader.module.scss';
import FormattedText from '../../lib/locale/formattedText';

const LocationHeader = ({ location }) => (
  <div className={style.container}>
    <div className={style.overlay} />
    <div className={style.content}>
      <Segment basic size="large">
        <Header as="h1">{location.name}</Header>
        <div>{location.address1}</div>
        <div>{location.city}</div>
        <div>{location.phone}</div>
        {/* TODO: open a modal */}
        <a href="#info">
          <FormattedText id="location-header.more-info" defaultMessage="More info" />
        </a>
      </Segment>
    </div>
  </div>
);

export default LocationHeader;
