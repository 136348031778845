// eslint-disable-next-line max-classes-per-file
import React from 'react';
import {
  Header,
  Input,
  Image,
  Menu,
} from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import FormattedText from '../../lib/locale/formattedText';

import HeaderLogo from '../../assets/img/header_logo.png';

const TopNav = () => (
  <div className="flex items-center justify-between py-3 px-5">
    <div className="flex items-center">
      <div>
        <Image src={HeaderLogo} style={{ maxHeight: '50px', width: 'auto' }} />
      </div>
      <Header size="large" style={{ margin: '0 10px', color: '#771803' }}>
        {process.env.REACT_APP_TITLE}
      </Header>
    </div>
    <div>
      <Menu text>
        <Menu.Item>
          <Header size="medium">
            <FormattedText id="home.about-us" defaultMessage="About Us" />
          </Header>
        </Menu.Item>

        <Menu.Item>
          <Header size="medium">
            <FormattedText id="home.contact-us" defaultMessage="Contact Us" />
          </Header>
        </Menu.Item>
      </Menu>
    </div>
  </div>
);

const HomepageBody = ({ intl }) => (
  <div className="w-full flex flex-col justify-center items-center my-20 px-8">
    <div className="w-full sm:w-3/4 md:w-3/5">
      <div>
        <h1 className="text-3xl sm:text-4xl lg:text-5xl text-white font-semibold">
          {intl.formatMessage({ id: 'home.title-food', defaultMessage: 'Find Food Near You' })}
        </h1>
      </div>
      <div>
        <Input
          size="big"
          fluid
          icon="search"
          placeholder={intl.formatMessage({ id: 'home.search-placeholder', defaultMessage: 'Input address...' })}
        />
      </div>
    </div>
  </div>
);

const ResponsiveContainer = ({ children }) => {
  const intl = useIntl();
  const backgroundUrl = 'https://djnx0nmnsa1e1.cloudfront.net/_assets/7f0198e50dba520b1db471d89dc6e417.jpg';
  return (
    <div className="flex flex-col h-screen">
      <TopNav />
      <div
        className="h-full"
        style={{
          background: `url("${backgroundUrl}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <HomepageBody intl={intl} />
      </div>

      {children}

      <footer className="h-16 text-xl font-bold px-4 flex items-center">
        @2020 JustStore.app
      </footer>
    </div>
  );
};

class Home extends React.Component {
  render() {
    return (
      <ResponsiveContainer />
    );
  }
}

export default Home;
