import React from 'react';
import { connect } from 'react-redux';
import CodeForm from '../../components/codeForm';
import BasicLayout from '../../components/basicLayout';
import { sendCode } from '../../reducers/auth';

const RequestCode = (props) => {
  const handleSubmit = (form) => props.sendCode(form);

  return (
    <BasicLayout style={{ marginTop: '100px' }}>
      <CodeForm onSubmit={handleSubmit} />
    </BasicLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCode: (form) => dispatch(sendCode(form)),
  };
};

export default connect(null, mapDispatchToProps)(RequestCode);
