import React from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import Sticky from 'react-stickynode';

const CategoryNav = ({ categories, handleClick }) => {
  return (
    <Sticky enabled top={65} innerZ={10}>
      <Segment>
        <Menu secondary>
          {categories.map((category) => {
            return (
              <Menu.Item
                as="a"
                title={category.name}
                key={category.id}
                onClick={() => handleClick(category.id)}
              >
                {category.name}
              </Menu.Item>
            );
          })}
        </Menu>
      </Segment>
    </Sticky>
  );
};

export default CategoryNav;
