import http from '../../lib/http';

const API = '/v1';

export const openOrder = (locationId) => http.post(`${API}/locations/${locationId}/orders`);
export const getOrder = (locationId, orderId) => http.get(`${API}/locations/${locationId}/orders/${orderId}`);
export const addItem = (locationId, orderId, item) => {
  return http.post(
    `${API}/locations/${locationId}/orders/${orderId}/items`,
    { data: item },
  );
};
export const cancelOrder = (locationId, orderId) => http.delete(`${API}/locations/${locationId}/orders/${orderId}`);

export const submitOrder = (locationId, orderId, payload) => {
  return http.post(
    `${API}/locations/${locationId}/orders/${orderId}/submit`,
    { data: payload },
  );
};

export const fetchOrderReceipt = (locationId, orderId) => {
  return http.get(
    `${API}/locations/${locationId}/orders/${orderId}/receipt`,
  );
};
