import {
  Button, Divider,
  Header,
  Icon,
  Image,
  Modal,
  Placeholder,
} from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';
import { deselectItem, fetchItem } from '../../reducers/item';
import {
  resetNewItem,
  setNewItemQuantity,
  setNewItemVariant,
  addItem, selectNewItemModifier, unselectNewItemModifier,
} from '../../reducers/order';
import ButtonAddItem from '../buttonAdItem';
import InputQuantity from '../inputQuantity';
import VariantSelector from '../variantSelector';
import ModifierGroup from '../modifierGroup';
import FormattedText from '../../lib/locale/formattedText';

const ModifierPlaceHolder = () => (
  <Placeholder fluid>
    <Placeholder.Line />
    <Placeholder.Line />
    <Placeholder.Line />
    <Placeholder.Line />
    <Placeholder.Line />
  </Placeholder>
);

const ItemModal = () => {
  const { location, item, ui } = useSelector((state) => state);
  const { newItem } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const {
    image,
    name,
    description,
    warning,
    variants,
    modifierGroups,
  } = item.selectedItem;

  const [hasRequiredMod, setHasRequired] = useState(false);

  const getQuantity = () => newItem.quantity;

  const increaseQuantity = () => {
    dispatch(setNewItemQuantity(getQuantity() + 1));
  };

  const decreaseQuantity = () => {
    const quantity = getQuantity();
    if (quantity - 1 > 0) {
      dispatch(setNewItemQuantity(quantity - 1));
    }
  };

  const handleVariantChange = (event, { value: variantId }) => {
    const selectedVariant = item.selectedItem.variants
      .find((variant) => variant._id === variantId);
    dispatch(setNewItemVariant(selectedVariant));
  };

  const handleModSelect = (modifier) => {
    dispatch(selectNewItemModifier(modifier));
  };

  const handleUnselectMod = (modifier) => {
    dispatch(unselectNewItemModifier(modifier));
  };

  const closeModal = () => {
    dispatch(resetNewItem());
    dispatch(deselectItem());
  };

  const addItemToOrder = () => {
    dispatch(addItem());
  };

  useEffect(() => {
    dispatch(fetchItem({ locationId: location._id, itemId: item.selectedItem._id }));
  }, [dispatch, location._id, item.selectedItem._id]);

  // for handling the state of 'Add' button
  useEffect(() => {
    if (modifierGroups) {
      const missingMod = modifierGroups.filter((mg) => {
        if (mg.minimum > 0) {
          const totalSelectedMods = newItem.modifiers
            .filter((mod) => mod.modifierGroupId === mg._id)
            .length;
          return mg.minimum > 0 && totalSelectedMods < mg.minimum;
        }
        return false;
      }).length > 0;
      setHasRequired(() => missingMod);
    }
  }, [newItem.modifiers, modifierGroups]);

  const { variant, quantity } = newItem;

  return (
    <Modal open closeIcon size="large" onClose={closeModal}>
      <Modal.Header><FormattedText id="item-modal.add-item" defaultMessage="Add Item" /></Modal.Header>
      <Modal.Content style={{ height: 600 }} image scrolling>
        {image && <Image wrapped size="medium" src={image} />}
        <Modal.Description style={{ width: '100%' }}>
          <Header size="large" as="h3">
            {name}
          </Header>
          <HeaderSubHeader>
            {description && <div style={{ marginBottom: '20px' }}>{description}</div>}
            {warning && <div>{warning}</div>}
          </HeaderSubHeader>
          {variants.length > 1 && (
            <VariantSelector
              variants={variants}
              defaultVariant={variant._id}
              handleVariantChange={handleVariantChange}
            />
          )}
          {modifierGroups ? modifierGroups.map((modGroup) => {
            return (
              <ModifierGroup
                key={modGroup.id}
                handleModSelect={handleModSelect}
                handleUnselectMod={handleUnselectMod}
                selectedMods={newItem.modifiers}
                modGroup={modGroup}
              />
            );
          }) : <ModifierPlaceHolder />}
          <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: '30px' }}>
            <InputQuantity
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              quantity={quantity}
            />
          </div>
          <div>
            <Button secondary onClick={closeModal}>
              <Icon name="close" />
              <FormattedText id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <ButtonAddItem
              disabled={hasRequiredMod}
              handleClick={addItemToOrder}
              loading={ui.button.loading}
            />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default ItemModal;
