import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Image,
} from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import Input from '../input';
import style from './code-form.module.scss';
import AuthLogo from '../../assets/img/auth_logo.svg';
import FormattedText from '../../lib/locale/formattedText';

const CodeForm = ({ handleSubmit }) => {
  const intl = useIntl();
  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="blue" textAlign="center">
          <Image src={AuthLogo} style={{ maxHeight: '150px', width: 'auto' }} />
        </Header>
        <Form size="large" onSubmit={handleSubmit}>
          <Segment className="middle aligned">
            <h2><FormattedText id="request-code.title" defaultMessage="Last step!" /></h2>
            <div className={style['form-description']}>
              <FormattedText id="request-code.description" defaultMessage="Enter the code you should have received on your mobile phone." />
            </div>
            <Field
              component={Input}
              name="code"
              icon="key"
              placeholder={intl.formatMessage({ id: 'request-code.code-field-placeholder', defaultMessage: '6-digits code' })}
              iconPosition="left"
            />
            <Button color="blue" fluid size="large">
              <FormattedText id="button.login" defaultMessage="Login" />
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};
export default reduxForm({ form: 'code' })(CodeForm);
