import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import history from '../store/history';
import { reducer as location } from './location';
import { reducer as item } from './item';
import { reducer as order } from './order';
import { reducer as ui } from './ui';
import { reducer as auth } from './auth';
import { reducer as receipt } from './receipt';
import { reducer as locale } from '../lib/locale/reducer';
import { reducer as errors } from './errors';

export default combineReducers({
  router: connectRouter(history),
  location,
  item,
  order,
  ui,
  form: formReducer,
  auth,
  locale,
  receipt,
  errors,
});
