import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import FormattedText from '../../lib/locale/formattedText';
import { SIGN_IN } from '../../constants/path';

class ButtonSignIn extends React.Component {
  render() {
    return (
      <Button color="facebook" as={Link} to={`${SIGN_IN}${this.props.queryParams}`}>
        <FormattedText id="button.sign-in" defaultMessage="Sign In" />
      </Button>
    );
  }
}

export default ButtonSignIn;
