import { handleActions, combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SIGN_IN_FAILURE, SIGN_UP_FAILURE } from './auth';

export const defaultState = {
  isError: false,
  message: null,
};

export const reducer = handleActions(
  {
    [LOCATION_CHANGE]: () => {
      return defaultState;
    },
    [combineActions(SIGN_IN_FAILURE, SIGN_UP_FAILURE)]: (state, { payload }) => ({
      ...state,
      isError: true,
      message: payload,
    }),
  },
  defaultState,
);
