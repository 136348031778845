import { call, put, takeLatest } from '@redux-saga/core/effects';
import { fetchItem } from '../constants/api/items';
import { fetchItemError, fetchItemSuccess, GET_ITEM } from '../reducers/item';
import { setNewItem } from '../reducers/order';

function* getItem({ payload }) {
  try {
    const { data: item } = yield call(fetchItem, payload);
    yield put(fetchItemSuccess(item));

    const {
      _id, id, name, variants,
    } = item;
    yield put(setNewItem({
      _id, id, name, variant: variants[0],
    })); // set new item order
  } catch (e) {
    yield put(fetchItemError());
  }
}

export function* watchItem() {
  yield takeLatest(GET_ITEM, getItem);
}
