import React from 'react';
import { connect } from 'react-redux';
import SignInForm from '../../components/signInForm';
import BasicLayout from '../../components/basicLayout';
import { signIn } from '../../reducers/auth';

const SignIn = (props) => {
  const onSubmit = (form) => props.signIn(form);

  return (
    <BasicLayout style={{ marginTop: '100px' }}>
      <SignInForm onSubmit={onSubmit} referrer={props.referrer} ui={props.ui} />
    </BasicLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    referrer: state.router.location.search,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (form) => dispatch(signIn(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
