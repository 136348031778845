import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Header, List } from 'semantic-ui-react';
import { cancelOrder } from '../../reducers/order';
import FormattedText from '../../lib/locale/formattedText';
import FormattedPrice from '../../lib/locale/formattedPrice';

const calculateItemPrice = (item) => {
  const { modifiers, quantity, price } = item;
  const modifierTotal = modifiers.map((modifier) => modifier.price).reduce((p, n) => p + n, 0);
  return Number(quantity) * (price + modifierTotal);
};

const Cart = ({ order, closeModal, currency }) => {
  const dispatch = useDispatch();

  const clearCart = () => {
    dispatch(cancelOrder());
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <Container>
      {order.items.length > 0 ? (
        <>
          <div className="text-red-500 text-xl text-right">
            <button
              type="button"
              onClick={clearCart}
            >
              <FormattedText id="cart-modal.clear-cart" defaultMessage="Clear cart" />
            </button>
          </div>
          {order.items.map((item) => (
            <List divided relaxed key={item.id}>
              <List.Item>
                <List.Content>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Header>
                        {item.name}
                      </Header>
                    </div>
                    <div>
                      <FormattedPrice currency={currency} value={calculateItemPrice(item)} />
                    </div>
                  </div>
                  {item.modifiers.length > 0 && (
                    item.modifiers.map((modifier) => (
                      <div style={{ padding: '5px' }} key={modifier._id}>{modifier.name}</div>
                    ))
                  )}
                </List.Content>
              </List.Item>
            </List>
          ))}
        </>
      ) : <div className="text-center font-bold text-xl"><FormattedText id="cart-modal.empty-warning" defaultMessage="Cart is empty!" /></div>}
    </Container>
  );
};

export default Cart;
