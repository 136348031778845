import { Button, Icon } from 'semantic-ui-react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import CartModal from '../cartModal';

const ButtonCart = ({ order }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <CartModal closeModal={() => setShowModal(false)} />}
      <Button primary={order.items.length > 0} onClick={() => setShowModal(true)} icon>
        <Icon name="cart" />
        {'  '}
        {order.items.length}
      </Button>
    </>
  );
};

export default connect(null, null)(ButtonCart);
