import { call, put, takeLatest } from '@redux-saga/core/effects';
import { GET_ORDER_RECEIPT, getOrderReceiptFailure, getOrderReceiptSuccess } from '../reducers/receipt';
import { fetchOrderReceipt } from '../constants/api/orders';

function* getReceipt({ payload }) {
  try {
    const { locationId, orderId } = payload;
    const { data } = yield call(fetchOrderReceipt, locationId, orderId);
    yield put(getOrderReceiptSuccess(data));
  } catch (e) {
    yield put(getOrderReceiptFailure());
  }
}

export function* watchReceipt() {
  yield takeLatest(GET_ORDER_RECEIPT, getReceipt);
}
