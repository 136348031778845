import React from 'react';
import BasicLayout from '../../components/basicLayout';

class Profile extends React.Component {
  render() {
    return (
      <BasicLayout>
        <div>
          Profile page
        </div>
      </BasicLayout>
    );
  }
}

export default Profile;
