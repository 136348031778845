import { Button, Icon } from 'semantic-ui-react';
import React from 'react';
import FormattedText from '../../lib/locale/formattedText';

const ButtonAddItem = ({ disabled, handleClick, loading = false }) => {
  return (
    <Button
      disabled={disabled}
      primary
      onClick={handleClick}
      loading={loading}
    >
      <Icon name="add" />
      <FormattedText id="button.add-item" defaultMessage="Add" />
    </Button>
  );
};

export default ButtonAddItem;
